import React from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  StyledSpan,
  DataImage,
  AbsoluiteImage,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import VideoBg from "../../../../assets/images/heroVideo.mp4";
import heroHeading from "../../../../assets/images/heroHeading.png";
import heroImage from "../../../../assets/images/heroImage.png";
import harambe from "../../../../assets/images/harambe.png";
import SectionHeading from "../../SectionHeading";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100dvh);
  width: 100%;
  position: relative;
  background-color: #fff;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const HeroSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
        <InnerContinerWraper>
          <CustomGrid container>
            <CustomGrid
              item
              container
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="center"
              jc="space-between"
              g="20px"
              sx={{minHeight:"calc(100vh - 120px)"}}
            >
              <AbsoluiteImage mw="35%" style={{
                top:"65%",
                left:"50%",
                transform:"translate(-50%,-60%)"
              }} src={harambe}/>
              <DataImage src={heroHeading} />
              <CustomGrid xs={12} fd="column" g="10px">
              <SectionHeading variant="h5">Contract Address</SectionHeading>
              <CustomGrid
              p="5px 20px"
              item
              xs={12}
              sm={10}
                sx={{
                  width: "100%",
                  padding: "10px 30px",
                  background:"#fff",
                  border: "2px solid #000",
                  boxShadow: "0px 3px 3px #000",
                  zIndex:"1",
                }}
                >
                <SectionHeading  variant="h5" sx={{wordBreak:"break-all"}}>0000xxxxxxxxxxxx0000x0x0x</SectionHeading>
              </CustomGrid>
                </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default HeroSection;
