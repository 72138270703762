import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";

import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import VideoBg from "../../../../assets/images/aboutVideo.mp4";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  DataImage,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import Tiker from "../../marquetag";
import SectionHeading from "../../SectionHeading";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh - 78px);
  background-color: #000000;
  width: 100%;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh - 78px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const AboutSection = () => {
  return (
    <>
      <SectionWraper id="about">
        <Tiker />
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
        <InnerContinerWraper sx={{ padding: { xs: "40px 10px", sm: "0" } }}>
          <CustomGrid container>
            <CustomGrid
              item
              container
              xs={12}
              sm={9}
              md={7}
              fd="column"
              ai="center"
              g="20px"
            >
              <SectionHeading variant="h2">About</SectionHeading>
              <Paragraph variant="h6">
                Hey you, degen! Ya you! You see harambe on Solana climb to 10
                million in 2 days?
                <br />
                <br />
                So did we.
                <br />
                <br />
                Harambe can't be a Solana only meme. Ethereum is the King Kong
                of billion dollar memes
                <br />
                <br />
                Now pound your hairy chest like Harambe, buy crypto with your
                dick out, and ape this absolute bad ass meme with some fuckin
                conviction.
                <br />
                <br />
                Harambe deserves a safe space on Ethereum, and that is what we
                provide. No shooting gorillas here. Only prayers and dicks out
                for harambe but with ETH, not with solanus.
                <br />
                <br />
                After you buy harambe on eth, pour one out for our homie and
                yell like a angry gorilla that Harambe exists on Ethereum too.
              </Paragraph>
              <CustomGrid item g="10px" xs={12}>
                <Link href="https://t.me/HarambeOnETH" target="blank">
                  <DataImage mw="60px" src={tg} />
                </Link>
                <Link href="https://twitter.com/harambeOnERC20" target="blank">
                  <DataImage mw="60px" src={x} />
                </Link>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default AboutSection;
