import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import tokenBg from "../../../../assets/images/tokenBg.png";
import tokenImage from "../../../../assets/images/tokenImage.png";
import {
  AbsoluiteImage,
  CustomGrid,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import SectionHeading from "../../SectionHeading";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  background-image: url(${tokenBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
  }
`;

const Tokenomics = () => {
  return (
    <>
      <SectionWraper id="home">
  <AbsoluiteImage mw="100%" style={{
            bottom:"0%",
            left:"0%",
          }} src={tokenImage}/>
        <InnerContinerWraper sx={{padding:{xs:"40px",sm:"0"}}} >
          <CustomGrid container g="30px">
            <CustomGrid xs={10}>

            <SectionHeading variant="h2" sx={{fontSize:{xs:"30px",sm:"50px"}}}>DicksOutForHarambe</SectionHeading>
            </CustomGrid>
            <CustomGrid
              item
              container
              xs={12}
              sm={9}
              md={7}
              lg={5.5}
              // fd="column"
              ai="center"
              jc="space-between"
              g="10px 0"
            >
              <CustomGrid
                xs={12}
                sm={12}
                md={2.5}
                sx={{
                  width: "100%",
                  background: "#1AAEC9",
                  border: "2px solid #000",
                  boxShadow: "0px 3px 3px #000",
                  zIndex: "1",
                }}
                fd="column" 
                ai="start"
                p="10px 20px"
                g="0px"
              >
                <Paragraph textAlign="left" variant="h6">Tax</Paragraph>
                <Paragraph textAlign="left" variant="h4">1/1</Paragraph>
              </CustomGrid>
              <CustomGrid
                xs={12}
                sm={12}
                md={4.5}
                sx={{
                  width: "100%",
                  background: "#43FF12",
                  border: "2px solid #000",
                  boxShadow: "0px 3px 3px #000",
                  zIndex: "1",
                }}
                fd="column" 
                ai="start"
                p="10px 20px"
                g="0px"
              >
                <Paragraph textAlign="left" variant="h6">Ownership</Paragraph>
                <Paragraph textAlign="left" variant="h4">Renounced</Paragraph>
              </CustomGrid>
              <CustomGrid
                xs={12}
                sm={12}
                md={4.5}
                sx={{
                  width: "100%",
                  background: "#F7FF12",
                  border: "2px solid #000",
                  boxShadow: "0px 3px 3px #000",
                  zIndex: "1",
                }}
                fd="column" 
                ai="start"
                p="10px 20px"
                g="0px"
              >
                <Paragraph textAlign="left" variant="h6">Liqudity</Paragraph>
                <Paragraph textAlign="left" variant="h4">Locked </Paragraph>
              </CustomGrid>
              <CustomGrid
                xs={12}
                sx={{
                  width: "100%",
                  background: "#D30004",
                  border: "2px solid #000",
                  boxShadow: "0px 3px 3px #000",
                  zIndex: "1",
                }}
                fd="column" 
                ai="start"
                p="10px 20px"
                g="0px"
              >
                <Paragraph textAlign="left" variant="h6">Distribution</Paragraph>
                <Paragraph textAlign="left" variant="h4">90% LP - 5% CEX - 5% Marketing</Paragraph>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Tokenomics;
