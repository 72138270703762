import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const Heading = styled(Typography)`
/* white-space: nowrap; */
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  /* font-size: ${(props) => (props.fs ? props.fs : "24px")}; */
  font-family: ${(props) => (props.fm ? props.fm : "ImpactR")};
  line-height: 1;
  letter-spacing: 1px;
  position: relative;
  z-index: 9;
  color: ${(props) => (props.c ? props.c : "#fff")};
  -webkit-text-stroke: 1.4px;
  -webkit-text-stroke-color: #000;
  text-shadow: 0px 3px 6px #000;
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    /* font-size: ${(props) => (props.fs599 ? props.fs1175 : "22px")}; */
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    /* font-size: ${(props) => (props.fs599 ? props.fs1024 : "20px")}; */
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    /* font-size: ${(props) => (props.fs599 ? props.fs599 : "18px")}; */
  }
`;

const SectionHeading = (props) => {
  const { children } = props;
  return (
    <Heading
      variant="h1"
      {...props}
    >
      {children}
    </Heading>
  );
};

export default SectionHeading;
