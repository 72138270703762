import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import SectionHeading from "../../SectionHeading";
import Aboutimg from "../../../../assets/images/Aboutimg.png";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  background-color: black;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
  }
`;

const Abimg = styled.img`
  width: 65%;
  height: auto;
`;

const HowToBuy = () => {
  return (
    <>
      <SectionWraper id="about">
        <InnerContinerWraper sx={{ padding: { xs: "40px 20px", sm: "0" } }}>
          <CustomGrid container>
            <CustomGrid item xs={12} sm={6}>
              <Abimg src={Aboutimg} alt="about image" />
            </CustomGrid>
            <CustomGrid item xs={12} sm={6} gap={3} fd="column" ai="start">
              <SectionHeading variant="h1" ta="left">
                How to Buy
              </SectionHeading>
              <br />
              <SectionHeading variant="h2" ta="left">
              Just pull your dick out and ape it on uniswap
              </SectionHeading>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default HowToBuy;
