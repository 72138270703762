import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const PageWrapper = styled.div`
  background-color: #000;
  position: relative;
  overflow: hidden;
  /* overflow: hidden !important; */
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t  )};
  left: ${(props) => (props.l )};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const AbsoluiteBackgroundVideo = styled.video`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  top: 0%;
  left: 0%;
  
`;
// const SectionHeading = styled(Typography)`
// /* white-space: nowrap; */
//   padding: ${(props) => (props.p ? props.p : "0px")};
//   margin: ${(props) => (props.m ? props.m : "0")};
//   text-align: ${(props) => (props.ta ? props.ta : "center")};
//   /* font-size: ${(props) => (props.fs ? props.fs : "24px")}; */
//   font-family: ${(props) => (props.fm ? props.fm : "ImpactR")};
//   line-height: 1;
//   letter-spacing: 3px;
//   position: relative;
//   text-transform: uppercase;
//   z-index: 9;
//   color: ${(props) => (props.c ? props.c : "#fff")};
//   -webkit-text-stroke: 2px;
//   -webkit-text-stroke-color: #000;
//   text-shadow: 0px 3px 3px #000;
//   @media (max-width: 1175px) {
//     text-align: ${(props) => props.ta599};
//     padding: ${(props) => props.p599};
//     /* font-size: ${(props) => (props.fs599 ? props.fs1175 : "22px")}; */
//   }
//   @media (max-width: 1024px) {
//     text-align: ${(props) => props.ta599};
//     padding: ${(props) => props.p599};
//     /* font-size: ${(props) => (props.fs599 ? props.fs1024 : "20px")}; */
//   }
//   @media (max-width: 599px) {
//     text-align: ${(props) => props.ta599};
//     padding: ${(props) => props.p599};
//     /* font-size: ${(props) => (props.fs599 ? props.fs599 : "18px")}; */
//   }
// `;

const CustomGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 599px) {
    flex-direction: ${(props) => props.fd599};
  }
`;
const ColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;
const StyledSpan = styled.span`
  color:#FF1036;
  text-decoration: line-through;
  text-decoration-color: #FF1036;
  
`
export {
  PageWrapper,
  DataImage,
  AbsoluiteImage,
  // SectionHeading,
  CustomGrid,
  ColumnGrid,AbsoluiteBackgroundVideo,StyledSpan
};
