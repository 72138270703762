import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";

const ParagraphText = styled(Typography)`
  /* font-size: ${(props) => (props.fs ? props.fs : "18px")}; */
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #000;
  text-shadow: 0px 3px 6px #000;
  line-height: ${(props) => (props.lh ? props.lh : "1.1")};
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    /* font-size: ${(props) => (props.fs599 ? props.fs599 : "16px")};   */
  }
`;

const Paragraph = (props) => {
  const { children } = props;
  return (
    <ParagraphText
    variant="body1"
      color="#fff"
      fontFamily="ImpactR"
      textAlign="center"
      padding="0px"
      margin="0px"
      lineHeight="1.4 !important"
      {...props}
    >
      {children}
    </ParagraphText>
  );
};

export default Paragraph;
