import React from "react";
import Marquee from "react-fast-marquee";
import { DataImage } from "../../Styles/style";
import Image from "../../../assets/images/PeteText.png";

const Tiker = () => (
  <div>
    <Marquee
      style={{ backgroundColor: "#0f0f0f", position: "relative", zIndex: "1" }}
    >
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
      <DataImage m="10px 0 0" src={Image} mw="100%" />
    </Marquee>
  </div>
);

export default Tiker;
