import styled from "@emotion/styled";
import { Container, Link } from "@mui/material";
import React from "react";
import comunityBg from "../../../../assets/images/comunityBg.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import logo from "../../../../assets/images/logo.png";
import {
  CustomGrid,
  DataImage,
} from "../../../Styles/style";
import SectionHeading from "../../SectionHeading";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(80vh);
  width: 100%;
  position: relative;
  background-image: url(${comunityBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;

  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(80vh);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const Community = () => {
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid container jc="center">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="center"
              g="20px"
            >
              <DataImage src={logo} mw="180px" />
              <SectionHeading variant="h4">Harambe</SectionHeading>
              <div style={{ display: "flex", gap: "10px" }}>
                <Link href="https://t.me/HarambeOnETH" target="blank">
                  <DataImage src={tg} mw="50px" />
                </Link>
                <Link href="https://twitter.com/harambeOnERC20" target="blank">
                  <DataImage src={x} mw="50px" />
                </Link>
              </div>
              <SectionHeading variant="h5">
              info@harambeeth.com
              </SectionHeading>
              <SectionHeading variant="h4">#DicksOutForHarambe</SectionHeading>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default Community;
